import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import mountain from '../../../assets/img/HomePage/Banner/mountain.png';
import smoke from '../../../assets/img/HomePage/Banner/smoke.png';
import horn from '../../../assets/img/HomePage/Banner/horn.png';
import bg from '../../../assets/img/HomePage/Banner/bg.png';
import stone_01 from '../../../assets/img/HomePage/Banner/stone_01.png';
import stone_02 from '../../../assets/img/HomePage/Banner/stone_02.png';
import stone_03 from '../../../assets/img/HomePage/Banner/stone_03.png';
import stone_04 from '../../../assets/img/HomePage/Banner/stone_04.png';
import stone_05 from '../../../assets/img/HomePage/Banner/stone_05.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const circleRef = useRef(null);
    const hornRef = useRef(null);
    const stoneWrapRef_01 = useRef(null);
    const stoneRef_01 = useRef(null);
    const stoneWrapRef_02 = useRef(null);
    const stoneRef_02 = useRef(null);
    const stoneWrapRef_03 = useRef(null);
    const stoneRef_03 = useRef(null);
    const stoneWrapRef_04 = useRef(null);
    const stoneRef_04 = useRef(null);
    const stoneWrapRef_05 = useRef(null);
    const stoneRef_05 = useRef(null);

    const animateStoneWrap = (target, y, x, rotate, scale) => {
        gsap.fromTo(target.current, {
            y, x, rotate, scale
        }, {
            y: 0,
            x: 0,
            rotate: 0,
            scale: 1,
            duration: 1.5,
        });
    };

    const animateStone = (target, y) => {
        gsap.timeline().to(target.current, {
            y,
            scrollTrigger: {
                trigger: bodyRef.current,
                start: "top+=150px top",
                scrub: 3,
            },
        });  
    };

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            animateStoneWrap(stoneWrapRef_01, -100, -30, 20, 0.8);
            animateStoneWrap(stoneWrapRef_02, -40, 10, 10, 0.8);
            animateStoneWrap(stoneWrapRef_03, -80, 0, -20, 0.8);
            animateStoneWrap(stoneWrapRef_04, -20, 0, -5, 0.95);
            animateStoneWrap(stoneWrapRef_05, -60, -10, -20, 0.9);

            animateStone(stoneRef_01, -70);
            animateStone(stoneRef_02, -150);
            animateStone(stoneRef_03, -100);
            animateStone(stoneRef_04, -50);
            animateStone(stoneRef_05, -170);

            gsap.fromTo(hornRef.current, {
                y: 30,
            }, {
                y: 0,
                duration: 1.5,
            });

            gsap.fromTo(circleRef.current, {
                y: -30,
            }, {
                y: 0,
                duration: 1.5,
            });
        }
    }, [])
    return (
        <section ref={bodyRef} className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-120`}>
                            UBT
                        </h1>
                        <h2 className={`${classes.subtitle} font-35`}>
                            {t('banner.subtitle')}
                        </h2>
                        <div className={classes.btns}>
                            <Button>
                                {t('btn.buy')}
                            </Button>
                            <Button>
                                {t('btn.sell')}
                            </Button>
                        </div>
                    </div>
                    <div ref={stoneWrapRef_01} className={`${classes.stone} ${classes.stone_01}`}>
                        <img ref={stoneRef_01} src={stone_01} alt='' />
                    </div>
                    <div ref={stoneWrapRef_02} className={`${classes.stone} ${classes.stone_02}`}>
                        <img ref={stoneRef_02} src={stone_02} alt='' />
                    </div>
                    <div ref={stoneWrapRef_03} className={`${classes.stone} ${classes.stone_03}`}>
                        <img ref={stoneRef_03} src={stone_03} alt='' />
                    </div>
                    <div ref={stoneWrapRef_04} className={`${classes.stone} ${classes.stone_04}`}>
                        <img ref={stoneRef_04} src={stone_04} alt='' />
                    </div>
                    <div ref={stoneWrapRef_05} className={`${classes.stone} ${classes.stone_05}`}>
                        <img ref={stoneRef_05} src={stone_05} alt='' />
                    </div>
                </div>
            </div>
            <img className={classes.mountain} src={mountain} alt='' />
            <img ref={hornRef} className={classes.horn} src={horn} alt='' />
            <img className={classes.bg} src={bg} alt='' />
            <img className={classes.smoke} src={smoke} alt='' />
            <div ref={circleRef} className={classes.circle}></div>
            <div className={classes.shadow}></div>
        </section>
    );
};

export default Banner;
