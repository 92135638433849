import React from 'react';
import Header from '../components/GlobalComponents/Header/Header';
import Banner from '../components/HomePage/Banner/Banner';
import TakeStep from '../components/HomePage/TakeStep/TakeStep';
import Cards from '../components/HomePage/Cards/Cards';
import DataAnalysis from '../components/HomePage/DataAnalysis/DataAnalysis';
import HowWorks from '../components/HomePage/HowWorks/HowWorks';
import Advantages from '../components/HomePage/Advantages/Advantages';
import Marquees from '../components/HomePage/Marquees/Marquees';
import Form from '../components/HomePage/Form/Form';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Noise from '../components/GlobalComponents/Noise/Noise';

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Noise />
      <Header />
      <Banner />
      <TakeStep />
      <Cards />
      <DataAnalysis />
      <HowWorks />
      <Advantages />
      <Marquees />
      <Form />
      <Footer />
    </div>
  );
};

export default HomePage;
