import React from "react";
import classes from './Form.module.scss';

const FormGroup = ({ type, value, setValue, valid, placeholder }) => {
    return (
        <div className={`${classes.formGroup} ${!valid ? classes.error : ''}`}>
            <input
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className={classes.input}
                placeholder={placeholder}
            />
            <div className={classes.label}>
                Enter
            </div>
        </div>
    );
};

export default FormGroup;
