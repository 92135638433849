import React from "react";
import classes from './DataAnalysis.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/data_analysis.jpg';
import Button from '../../GlobalComponents/Button/Button';

const DataAnalysis = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.dataAnalysis}>
                <h2 className={classes.head}>
                    <div className={`${classes.title} font-80 anim-3`}>
                        <span>
                            {t('data_analysis.title')}
                        </span>
                    </div>
                    <div className={`${classes.subtitle} font-50`}>
                        <Trans>
                            {t('data_analysis.subtitle')}
                        </Trans>
                    </div>
                </h2>
                <p className={`${classes.text} font-18`}>
                    <Trans>
                        {t('data_analysis.text')}
                    </Trans>
                </p>
                <div className={classes.btn}>
                    <Button telegram />
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default DataAnalysis;
