import React from "react";
import classes from './TakeStep.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/take_step.jpg';
import Cookies from "js-cookie";
import Button from '../../GlobalComponents/Button/Button';

const TakeStep = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <section className="mt container">
            <div className={classes.takeStep}>
                <h2 className={`${classes.title} font-115-en`}>
                    <Trans>
                        {t('takestep.title')}
                    </Trans>
                </h2>
                <img className={`${classes.image} ${lang === 'en' ? classes.image_en : ''}`} src={image} alt='' />
                <div className={classes.content}>
                    <p className={`${classes.text} font-18`}>
                        <Trans>
                            {t('takestep.text')}
                        </Trans>
                    </p>
                    <h4 className={`${classes.subtitle} font-75`}>
                        <Trans>
                            {t('takestep.subtitle')}
                        </Trans>
                    </h4>
                </div>
                <div className={classes.btn}>
                    <Button telegram />
                </div>
            </div>
        </section>
    );
};

export default TakeStep;
