import React, { useState, useEffect, useRef } from "react";
import classes from './Advantages.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Advantages/image_01.jpg';
import image_02 from '../../../assets/img/HomePage/Advantages/image_02.jpg';
import image_03 from '../../../assets/img/HomePage/Advantages/image_03.jpg';
import image_04 from '../../../assets/img/HomePage/Advantages/image_04.jpg';
import item_01 from '../../../assets/img/HomePage/Advantages/item_01.jpg';
import item_02 from '../../../assets/img/HomePage/Advantages/item_02.jpg';
import item_03 from '../../../assets/img/HomePage/Advantages/item_03.jpg';
import item_04 from '../../../assets/img/HomePage/Advantages/item_04.jpg';
import item_05 from '../../../assets/img/HomePage/Advantages/item_05.jpg';
import item_06 from '../../../assets/img/HomePage/Advantages/item_06.jpg';
import item_07 from '../../../assets/img/HomePage/Advantages/item_07.jpg';
import item_08 from '../../../assets/img/HomePage/Advantages/item_08.jpg';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Advantages = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const itemWrapRef_01 = useRef(null);
    const itemWrapRef_02 = useRef(null);
    const itemWrapRef_03 = useRef(null);
    const itemWrapRef_04 = useRef(null);
    const itemWrapRef_05 = useRef(null);
    const itemWrapRef_06 = useRef(null);
    const itemWrapRef_07 = useRef(null);
    const itemWrapRef_08 = useRef(null);
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);
    const itemRef_03 = useRef(null);
    const itemRef_04 = useRef(null);
    const itemRef_05 = useRef(null);
    const itemRef_06 = useRef(null);
    const itemRef_07 = useRef(null);
    const itemRef_08 = useRef(null);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const animateItem = (wrap, target, y, x) => {
        gsap.timeline().fromTo(target.current, {
            y, x
        }, {
            y: 0,
            x: 0,
            scrollTrigger: {
                trigger: wrap.current,
                start: "top bottom",
                end: "+=400px",
                scrub: 4,
            }
        });
    };

    useEffect(() => {
        if (windowWidth > 1220) {
            setTimeout(() => {
                animateItem(itemWrapRef_01, itemRef_01, 60, 20);
                animateItem(itemWrapRef_02, itemRef_02, 150, 10);
                animateItem(itemWrapRef_03, itemRef_03, 200, -10);
                animateItem(itemWrapRef_04, itemRef_04, 100, 0);
                animateItem(itemWrapRef_05, itemRef_05, 50, -10);
                animateItem(itemWrapRef_06, itemRef_06, 100, 20);
                animateItem(itemWrapRef_07, itemRef_07, 200, 10);
                animateItem(itemWrapRef_08, itemRef_08, 60, -20);
            }, 300);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <section className="mt container">
            <div className={classes.advantages}>
                <h2 className={`${classes.title} font-50`}>
                    <Trans>
                        {t('advantages.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.wrap}>
                            <div className={classes.content}>
                                <h4 className={`${classes.cardTitle} font-18-title`}>
                                    | <Trans>{t('advantages.card_01.title')}</Trans> |
                                </h4>
                                <p className={`${classes.cardText} font-18`}>
                                    {t('advantages.card_01.text')}
                                </p>
                            </div>
                            <img className={`${classes.image} ${classes.image_01}`} src={image_01} alt='' />
                        </div>
                        <div className={classes.line}></div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.wrap}>
                            <img className={`${classes.image} ${classes.image_02}`} src={image_02} alt='' />
                            <div className={classes.content}>
                                <h4 className={`${classes.cardTitle} font-18-title`}>
                                    | <Trans>{t('advantages.card_02.title')}</Trans> |
                                </h4>
                                <p className={`${classes.cardText} font-18`}>
                                    {t('advantages.card_02.text')}
                                </p>
                            </div>
                        </div>
                        <div className={classes.line}></div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.wrap}>
                            <div className={classes.content}>
                                <h4 className={`${classes.cardTitle} font-18-title`}>
                                    | <Trans>{t('advantages.card_03.title')}</Trans> |
                                </h4>
                                <p className={`${classes.cardText} font-18`}>
                                    {t('advantages.card_03.text')}
                                </p>
                            </div>
                            <img className={`${classes.image} ${classes.image_03}`} src={image_03} alt='' />
                        </div>
                        <div className={classes.line}></div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.wrap}>
                            <img className={`${classes.image} ${classes.image_04}`} src={image_04} alt='' />
                            <div className={classes.content}>
                                <h4 className={`${classes.cardTitle} font-18-title`}>
                                    | <Trans>{t('advantages.card_04.title')}</Trans> |
                                </h4>
                                <p className={`${classes.cardText} font-18`}>
                                    {t('advantages.card_04.text')}
                                </p>
                            </div>
                        </div>
                        <div className={classes.line}></div>
                    </div>
                    {windowWidth > 1220 &&
                        <>
                            <div ref={itemWrapRef_01} className={`${classes.item} ${classes.item_01}`}>
                                <img ref={itemRef_01} src={item_01} alt='' />
                            </div>
                            <div ref={itemWrapRef_02} className={`${classes.item} ${classes.item_02}`}>
                                <img ref={itemRef_02} src={item_02} alt='' />
                            </div>
                            <div ref={itemWrapRef_03} className={`${classes.item} ${classes.item_03}`}>
                                <img ref={itemRef_03} src={item_03} alt='' />
                            </div>
                            <div ref={itemWrapRef_04} className={`${classes.item} ${classes.item_04}`}>
                                <img ref={itemRef_04} src={item_04} alt='' />
                            </div>
                            <div ref={itemWrapRef_05} className={`${classes.item} ${classes.item_05}`}>
                                <img ref={itemRef_05} src={item_05} alt='' />
                            </div>
                            <div ref={itemWrapRef_06} className={`${classes.item} ${classes.item_06}`}>
                                <img ref={itemRef_06} src={item_06} alt='' />
                            </div>
                            <div ref={itemWrapRef_07} className={`${classes.item} ${classes.item_07}`}>
                                <img ref={itemRef_07} src={item_07} alt='' />
                            </div>
                            <div ref={itemWrapRef_08} className={`${classes.item} ${classes.item_08}`}>
                                <img ref={itemRef_08} src={item_08} alt='' />
                            </div>
                        </>
                    }
                </div>
            </div>
        </section>
    );
};

export default Advantages;
