import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/HomePage/Banner/bg.png';
import img2 from '../assets/img/HomePage/Banner/horn.png';
import img3 from '../assets/img/HomePage/Banner/mountain.png';
import img4 from '../assets/img/HomePage/Banner/smoke.png';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
];
